export function titleCase(word: string): string {
    word = word.toLowerCase();
    return word.replace(word[0], word[0].toUpperCase());
}

export function camelize(str: string): string {
    // Convert snake_case to camelCase
    let camelCaseStr = str.replace(/_./g, match => match.charAt(1).toUpperCase());

    // Capitalize the first letter
    return camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
}

export function createElement<T extends HTMLElement = HTMLElement>(content: string): T {
    const div = document.createElement('div');
    div.innerHTML = content?.toString()?.trim();
    if (div.children.length > 1) {
        throw new Error('content has more than one element.');
    }
    return div.firstChild as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function flattenKeysForUrl(obj: any, rootKey = '', mergeTo = {}) {
    Object.keys(obj).forEach((key) => {
        const totalKey = rootKey ? `${rootKey}[${key}]` : key;
        if (typeof obj[key] == 'object') {
            flattenKeysForUrl(obj[key], totalKey, mergeTo);
        } else {
            mergeTo[totalKey] = obj[key];
        }
    });

    return mergeTo;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringifyParams(params: Record<string, any>, separator = '&', eq = '=') {
    const obj = flattenKeysForUrl(params);
    return Object.keys(obj)
        .map((key) => `${encodeURIComponent(key)}${eq}${encodeURIComponent(obj[key])}`)
        .join(separator);
}

export function extendDataset(currentSet, newValue) {
    return currentSet ? currentSet + ' ' + newValue : newValue;
}

