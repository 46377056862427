import { Grid } from '@components';
import { PageOptions, SortOptions } from '@components/shared';
import { stringifyParams } from '@utils';
import ApplicationController from './application_controller';
import consumer from '../channels/consumer';

export default class extends ApplicationController {
    static targets = [
        'gridContainer'
    ];

    declare gridContainerTarget: HTMLElement;
    private parmsObj: ParamsObj = {};
    static values = {
        confirmActionFormId: String,
        url: {
            type: String,
            default: '',
        },
    };

    declare urlValue: string;
    private grid: Grid<Upload>;
    private disabled: boolean;
    private productUploadsSubscription: any;
    declare entityId: string;

    connect(): void {
        this.entityId = this.element.dataset.orgId;
        this.productUploadsSubscription = consumer.subscriptions.create(
            {
                channel: 'ProductUploadsChannel',
                user_id: this.element.dataset.userId,
            },
            {
                received: this.updateGrid.bind(this),
            }
        );

        let productUploadsParams = new URLSearchParams(window.location.search);
        this.parmsObj.page = {
            size: parseInt(productUploadsParams.get('page[size]')) || 30,
            number: parseInt(productUploadsParams.get('page[number]')) || 1
        };

        this.createGrid();
        // Apply default filters before reloading the data
        this.applyFilters({ detail: {} } as CustomEvent);
    }

    disconnect() {
        this.productUploadsSubscription && this.productUploadsSubscription.unsubscribe();
        super.disconnect();
    }

    updateGrid(): void {
        this.applyFilters({ detail: {} } as CustomEvent);
    }

    private createGrid() {
        this.grid = new Grid<Upload>({
            key: 'id',
            allowSelection: false,
            showEmptyState: true,
            noRecordsTemplate: this.noRecordsTemplate,
            container: this.gridContainerTarget,
            columns: [
                {
                    type: 'checkbox',
                    hideColumn: true,
                },
                {
                    headerTitle: 'Uploaded At',
                    field: 'uploaded_at',
                    width: '144px',
                    sortable: true,
                    cellTemplate: (uploadRecord) => {
                        return `
                            <span class="ow-anywhere min-w-52 pr-1"> 
                               ${uploadRecord.attributes.uploaded_at}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Status',
                    field: 'status',
                    width: '128px',
                    sortable: true,
                    cellTemplate: (uploadRecord) => {
                        return `
                            <span class="ow-anywhere min-w-32 pr-1"> 
                                ${uploadRecord.attributes.status}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Uploaded By',
                    field: 'uploaded_by',
                    hideColumn: screen.width <= 1024 ? true : false,
                    width: '210px',
                    cellTemplate: (uploadRecord) => {
                        return `
                            <div class="text-sm variant-truncate-container w-52">
								<span class="variant-truncate-text" title="${uploadRecord.attributes.uploaded_by}">
                                ${uploadRecord.attributes.uploaded_by}
								</span>
							</div>
                        `;
                    }
                },
                {
                    headerTitle: 'SKUs Updated',
                    field: 'skus_updated',
                    width: '75px',
                    cellTemplate: (uploadRecord) => {
                        return `
                            <span class="ow-anywhere min-w-20"> 
                                ${uploadRecord.attributes.skus_updated}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'App',
                    field: 'app',
                    hideColumn: screen.width <= 1024 ? true : false,
                    cellTemplate: (uploadRecord) => {
                        return `
                            <span class="ow-anywhere min-w-20"> 
                                ${uploadRecord.attributes.app}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Location',
                    field: 'location',
                    cellTemplate: (uploadRecord) => {
                        return `
                            <span class="ow-anywhere min-w-20"> 
                                ${uploadRecord.attributes.location}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Missing Cost',
                    field: 'missing_cost',
                    width: '75px',
                    cellTemplate: (uploadRecord) => {
                        return `
                            <span class="ow-anywhere min-w-20"> 
                                ${uploadRecord.attributes.missing_costs}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Action',
                    width: '75px',
                    cellTemplate: (uploadRecord) => {
                        return `
                            <span class="cursor-pointer ow-anywhere min-w-20 text-bkblue ${uploadRecord.attributes.missing_costs > 0 ? '' : 'hidden' }">
                                Fix Cost
                            </span>
                        `;
                    },
                    onCellClicked: (rowData: Upload) => {
                        (window as any).openPopup(`/products/${this.entityId}/uploads_fix_cost?id=${rowData.attributes.id}`);
                    }
                },
                {
                    headerTitle: '',
                    cellTemplate: () => {
                        return `
                            <span class="cursor-pointer ow-anywhere w-6 text-bkblue">
                                <svg xmlns="http://www.w3.org/2000/svg" class='h-5 w-5' viewBox="0 0 24 24" fill="none">
                                    <path d="M17 17H17.01M17.4 14H18C18.9319 14 19.3978 14 19.7654 14.1522C20.2554 14.3552 20.6448 14.7446 20.8478 15.2346C21 15.6022 21 16.0681 21 17C21 17.9319 21 18.3978 20.8478 18.7654C20.6448 19.2554 20.2554 19.6448 19.7654 19.8478C19.3978 20 18.9319 20 18 20H6C5.06812 20 4.60218 20 4.23463 19.8478C3.74458 19.6448 3.35523 19.2554 3.15224 18.7654C3 18.3978 3 17.9319 3 17C3 16.0681 3 15.6022 3.15224 15.2346C3.35523 14.7446 3.74458 14.3552 4.23463 14.1522C4.60218 14 5.06812 14 6 14H6.6M12 15V4M12 15L9 12M12 15L15 12" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        `;
                    },
                    onCellClicked: (rowData: Upload) => {
                        window.open(`/product_uploads/${this.entityId}/download_file?id=${rowData.attributes.id}`, "_blank");
                    }
                }
            ],
            pageOptions: {
                number: this.parmsObj.page.number,
                size: this.parmsObj.page.size,
            },
            onSort: (sort: SortOptions) => this.sort(sort),
            onPageChange: (page: PageOptions) => this.page(page)
        });
    }

    private noRecordsTemplate = () => {
        let message = 'No records to show.';
        return `<div class="h-96 flex justify-center items-center text-gray-600 px-4">
        <div class="text-center"> ${message} </div>`;
    };

    previous(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.page.number = (+this.parmsObj.page.number || 0) - 1;
        this.reload();
    }

    next(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.page.number = (+this.parmsObj.page.number || 0) + 1;
        this.reload();
    }

    disableButtons(): void {
        this.disabled = true;
    }

    enableButtons(): void {
        this.disabled = false;
    }

    reload(): void {
        let url = this.urlValue || '';
        let currentPageSize = this.parmsObj.page.size;
        const query = stringifyParams(this.parmsObj);
        if (query.length) {
            url = url + '?' + query;
            window.history.pushState({}, '', url);
        }
        this.ajax({
            url,
            type: 'GET',
            dataType: 'json',
            success: (data) => {
                this.grid.pageOptions.size = currentPageSize;
                this.grid.pageOptions.number = data.meta.pagination.current;
                this.grid.totalResultsCount = data.meta.pagination.records;
                this.grid.data = data.data;
            },
        });
    }

    pageSize(e: Event): void {
        this.parmsObj.page.number = 1;
        e.preventDefault();
        this.reload();
    }

    private sort(sort: SortOptions): void {
        this.parmsObj.sort = sort.direction == 'asc' ? sort.column : '-' + sort.column
        this.reload();
    }

    private page(page: PageOptions): void {
        this.parmsObj.page = {
            number: page.number,
            size: page.size
        };
        this.reload();
    }

    resetSelected(): void {
        this.grid.resetSelection();
    }

    applyFilters(e: CustomEvent): void {
        this.resetSelected();
        this.reload();
    }
}

interface ParamsPageObj {
    number?: number
    size?: number
}

interface ParamsObj {
    id?: string;
    filter?: object;
    status?: string;
    sort?: string;
    page?: ParamsPageObj;
    notifications_status?: string;
}

interface UploadAttributes {
    id: number;
    uploaded_at: Date;
    uploaded_by: string;
    skus_updated: number;
    app: string;
    location: string;
    missing_costs: number;
    status: string;
}

interface Upload {
    attributes: UploadAttributes;
}